import React, { useEffect } from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import ProductSlider from "./shop-components/product-slider-v1";
import ProductDetails from "./shop-components/shop-details";
// import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";

const Product_Details = ({ location }) => {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <PageHeader headertitle={location.state.title} customclass="mb-0" />
      <ProductSlider location={location} />
      <ProductDetails location={location} />
      {/* <CallToActionV1 /> */}
      <Footer />
    </div>
  );
};

export default Product_Details;
