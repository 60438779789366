import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import InquiryDialog from "./inquiry-dialog";

function ShopDetails(props) {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const receivedData = props.location.state;
  // Create a state variable to track the visibility of the InquiryDialog
  const [showInquiryDialog, setShowInquiryDialog] = useState(false);
  useEffect(() => {
    //stop scrolling on the background
    if (showInquiryDialog) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showInquiryDialog]);
  return (
    <div className="ltn__shop-details-area pb-10">
      {showInquiryDialog && (
        <InquiryDialog
          setOpenInquiryDialog={() => {
            setShowInquiryDialog(false);
          }}
        />
      )}
      <div className="container">
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        ></div>
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
              <div className="ltn__blog-meta"></div>
              <div
                className="ltn__shop-details-desc"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h1 style={{ margin: 0 }}>{receivedData?.title}</h1>
                <button
                  style={{
                    paddingTop: "6px",
                    paddingBottom: "6px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    backgroundColor: "var(--ltn__secondary-color)",
                    color: "white",
                    borderRadius: "5px",
                  }}
                  onClick={() => setShowInquiryDialog(true)}
                >
                  Send inquiry
                </button>
              </div>
              <label>
                <span className="ltn__secondary-color">
                  <i className="flaticon-pin" />
                </span>{" "}
                {receivedData?.location}
              </label>
              <h4 className="title-2">Description</h4>
              <p
                dangerouslySetInnerHTML={{
                  __html: receivedData?.description,
                }}
              />

              {/* <h4 className="title-2">Property Detail</h4>
                  <div className="property-detail-info-list section-bg-1 clearfix mb-60">
                    <ul>
                      <li>
                        <label>Property ID:</label> <span>HZ29</span>
                      </li>
                      <li>
                        <label>Home Area: </label> <span>120 sqft</span>
                      </li>
                      <li>
                        <label>Rooms:</label> <span>7</span>
                      </li>
                      <li>
                        <label>Baths:</label> <span>2</span>
                      </li>
                      <li>
                        <label>Year built:</label> <span>1992</span>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <label>Lot Area:</label> <span>HZ29 </span>
                      </li>
                      <li>
                        <label>Lot dimensions:</label> <span>120 sqft</span>
                      </li>
                      <li>
                        <label>Beds:</label> <span>7</span>
                      </li>
                      <li>
                        <label>Price:</label> <span>2</span>
                      </li>
                      <li>
                        <label>Property Status:</label> <span>For Sale</span>
                      </li>
                    </ul>
                  </div>
                  <h4 className="title-2">Facts and Features</h4>
                  <div className="property-detail-feature-list clearfix mb-45">
                    <ul>
                      <li>
                        <div className="property-detail-feature-list-item">
                          <i className="flaticon-double-bed" />
                          <div>
                            <h6>Living Room</h6>
                            <small>20 x 16 sq feet</small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="property-detail-feature-list-item">
                          <i className="flaticon-double-bed" />
                          <div>
                            <h6>Garage</h6>
                            <small>20 x 16 sq feet</small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="property-detail-feature-list-item">
                          <i className="flaticon-double-bed" />
                          <div>
                            <h6>Dining Area</h6>
                            <small>20 x 16 sq feet</small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="property-detail-feature-list-item">
                          <i className="flaticon-double-bed" />
                          <div>
                            <h6>Bedroom</h6>
                            <small>20 x 16 sq feet</small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="property-detail-feature-list-item">
                          <i className="flaticon-double-bed" />
                          <div>
                            <h6>Bathroom</h6>
                            <small>20 x 16 sq feet</small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="property-detail-feature-list-item">
                          <i className="flaticon-double-bed" />
                          <div>
                            <h6>Gym Area</h6>
                            <small>20 x 16 sq feet</small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="property-detail-feature-list-item">
                          <i className="flaticon-double-bed" />
                          <div>
                            <h6>Garden</h6>
                            <small>20 x 16 sq feet</small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="property-detail-feature-list-item">
                          <i className="flaticon-double-bed" />
                          <div>
                            <h6>Parking</h6>
                            <small>20 x 16 sq feet</small>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div> */}
              {/* <h4 className="title-2">From Our Gallery</h4>
                  <div className="ltn__property-details-gallery mb-30">
                    <div className="row">
                      <div className="col-md-6">
                        <a
                          href={publicUrl + "assets/img/others/14.jpg"}
                          data-rel="lightcase:myCollection">
                          <img
                            className="mb-30"
                            src={publicUrl + "assets/img/others/14.jpg"}
                            alt="Image"
                          />
                        </a>
                        <a
                          href={publicUrl + "assets/img/others/15.jpg"}
                          data-rel="lightcase:myCollection">
                          <img
                            className="mb-30"
                            src={publicUrl + "assets/img/others/15.jpg"}
                            alt="Image"
                          />
                        </a>
                      </div>
                      <div className="col-md-6">
                        <a
                          href={publicUrl + "assets/img/others/16.jpg"}
                          data-rel="lightcase:myCollection">
                          <img
                            className="mb-30"
                            src={publicUrl + "assets/img/others/16.jpg"}
                            alt="Image"
                          />
                        </a>
                      </div>
                    </div>
                  </div> */}
              {/* <h4 className="title-2 mb-10">Amenities</h4>
                  <div className="property-details-amenities mb-60">
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="ltn__menu-widget">
                          <ul>
                            <li>
                              <label className="checkbox-item">
                                Air Conditioning
                                <input type="checkbox" defaultChecked="checked" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li>
                              <label className="checkbox-item">
                                Gym
                                <input type="checkbox" defaultChecked="checked" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li>
                              <label className="checkbox-item">
                                Microwave
                                <input type="checkbox" defaultChecked="checked" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li>
                              <label className="checkbox-item">
                                Swimming Pool
                                <input type="checkbox" defaultChecked="checked" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li>
                              <label className="checkbox-item">
                                WiFi
                                <input type="checkbox" defaultChecked="checked" />
                                <span className="checkmark" />
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="ltn__menu-widget">
                          <ul>
                            <li>
                              <label className="checkbox-item">
                                Barbeque
                                <input type="checkbox" defaultChecked="checked" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li>
                              <label className="checkbox-item">
                                Recreation
                                <input type="checkbox" defaultChecked="checked" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li>
                              <label className="checkbox-item">
                                Microwave
                                <input type="checkbox" defaultChecked="checked" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li>
                              <label className="checkbox-item">
                                Basketball Cout
                                <input type="checkbox" defaultChecked="checked" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li>
                              <label className="checkbox-item">
                                Fireplace
                                <input type="checkbox" defaultChecked="checked" />
                                <span className="checkmark" />
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="ltn__menu-widget">
                          <ul>
                            <li>
                              <label className="checkbox-item">
                                Refrigerator
                                <input type="checkbox" defaultChecked="checked" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li>
                              <label className="checkbox-item">
                                Window Coverings
                                <input type="checkbox" defaultChecked="checked" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li>
                              <label className="checkbox-item">
                                Washer
                                <input type="checkbox" defaultChecked="checked" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li>
                              <label className="checkbox-item">
                                24x7 Security
                                <input type="checkbox" defaultChecked="checked" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li>
                              <label className="checkbox-item">
                                Indoor Game
                                <input type="checkbox" defaultChecked="checked" />
                                <span className="checkmark" />
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div> */}
              {/* <h4 className="title-2">Location</h4>
                  <div className="property-details-google-map mb-60">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9334.271551495209!2d-73.97198251485975!3d40.668170674982946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25b0456b5a2e7%3A0x68bdf865dda0b669!2sBrooklyn%20Botanic%20Garden%20Shop!5e0!3m2!1sen!2sbd!4v1590597267201!5m2!1sen!2sbd"
                      width="100%"
                      height="100%"
                      frameBorder={0}
                      allowFullScreen
                      aria-hidden="false"
                      tabIndex={0}
                    />
                  </div> */}

              {/* <h4 className="title-2">Related Properties</h4> */}
              {/* <div className="row"> */}
              {/* ltn__product-item */}
              {/* <div className="col-xl-6 col-sm-6 col-12 go-top">
                      <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                        <div className="product-img">
                          <Link to="/shop">
                            <img
                              src={publicUrl + "assets/img/product-3/1.jpg"}
                              alt="#"
                            />
                          </Link>
                          <div className="real-estate-agent">
                            <div className="agent-img">
                              <Link to="/team-details">
                                <img
                                  src={publicUrl + "assets/img/blog/author.jpg"}
                                  alt="#"
                                />
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="product-info">
                          <div className="product-badge">
                            <ul>
                              <li className="sale-badg">For Rent</li>
                            </ul>
                          </div>
                          <h2 className="product-title">
                            <Link to="/shop">New Apartment Nice View</Link>
                          </h2>
                          <div className="product-img-location">
                            <ul>
                              <li>
                                <Link to="/shop">
                                  <i className="flaticon-pin" /> Belmont Gardens,
                                  Chicago
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                            <li>
                              <span>3 </span>
                              Bedrooms
                            </li>
                            <li>
                              <span>2 </span>
                              Bathrooms
                            </li>
                            <li>
                              <span>3450 </span>
                              square Ft
                            </li>
                          </ul>
                          <div className="product-hover-action">
                            <ul>
                              <li>
                                <a
                                  href="#"
                                  title="Quick View"
                                  data-bs-toggle="modal"
                                  data-bs-target="#quick_view_modal">
                                  <i className="flaticon-expand" />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  title="Wishlist"
                                  data-bs-toggle="modal"
                                  data-bs-target="#liton_wishlist_modal">
                                  <i className="flaticon-heart-1" />
                                </a>
                              </li>
                              <li>
                                <Link to="/shop" title="Compare">
                                  <i className="flaticon-add" />
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="product-info-bottom">
                          <div className="product-price">
                            <span>
                              $349,00<label>/Month</label>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div> */}
              {/* ltn__product-item */}
              {/* <div className="col-xl-6 col-sm-6 col-12 go-top">
                      <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                        <div className="product-img">
                          <Link to="/shop">
                            <img
                              src={publicUrl + "assets/img/product-3/2.jpg"}
                              alt="#"
                            />
                          </Link>
                          <div className="real-estate-agent">
                            <div className="agent-img">
                              <Link to="/team-details">
                                <img
                                  src={publicUrl + "assets/img/blog/author.jpg"}
                                  alt="#"
                                />
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="product-info">
                          <div className="product-badge">
                            <ul>
                              <li className="sale-badg">For Sale</li>
                            </ul>
                          </div>
                          <h2 className="product-title">
                            <Link to="/shop">New Apartment Nice View</Link>
                          </h2>
                          <div className="product-img-location">
                            <ul>
                              <li>
                                <Link to="/shop">
                                  <i className="flaticon-pin" /> Belmont Gardens,
                                  Chicago
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                            <li>
                              <span>3 </span>
                              Bedrooms
                            </li>
                            <li>
                              <span>2 </span>
                              Bathrooms
                            </li>
                            <li>
                              <span>3450 </span>
                              square Ft
                            </li>
                          </ul>
                          <div className="product-hover-action">
                            <ul>
                              <li>
                                <a
                                  href="#"
                                  title="Quick View"
                                  data-bs-toggle="modal"
                                  data-bs-target="#quick_view_modal">
                                  <i className="flaticon-expand" />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  title="Wishlist"
                                  data-bs-toggle="modal"
                                  data-bs-target="#liton_wishlist_modal">
                                  <i className="flaticon-heart-1" />
                                </a>
                              </li>
                              <li>
                                <a href="portfolio-details.html" title="Compare">
                                  <i className="flaticon-add" />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="product-info-bottom">
                          <div className="product-price">
                            <span>
                              $349,00<label>/Month</label>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
            </div>
          </div>
          <div className="col-lg-4">
            <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
              {/* Author Widget */}
              {/* <div className="widget ltn__author-widget">
                    <div className="ltn__author-widget-inner text-center">
                      <img
                        src={publicUrl + "assets/img/team/4.jpg"}
                        alt="Image"
                      />
                      <h5>Rosalina D. Willaimson</h5>
                      <small>Traveller/Photographer</small>
                      <div className="product-ratting">
                        <ul>
                          <li>
                            <a href="#">
                              <i className="fas fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fas fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fas fa-star" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fas fa-star-half-alt" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="far fa-star" />
                            </a>
                          </li>
                          <li className="review-total">
                            {" "}
                            <a href="#"> ( 1 Reviews )</a>
                          </li>
                        </ul>
                      </div>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        Veritatis distinctio, odio, eligendi suscipit
                        reprehenderit atque.
                      </p>
                      <div className="ltn__social-media">
                        <ul>
                          <li>
                            <a href="#" title="Facebook">
                              <i className="fab fa-facebook-f" />
                            </a>
                          </li>
                          <li>
                            <a href="#" title="Twitter">
                              <i className="fab fa-twitter" />
                            </a>
                          </li>
                          <li>
                            <a href="#" title="Linkedin">
                              <i className="fab fa-linkedin" />
                            </a>
                          </li>
                          <li>
                            <a href="#" title="Youtube">
                              <i className="fab fa-youtube" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> */}
              {/* Search Widget */}
              {/* <div className="widget ltn__search-widget">
                    <h4 className="ltn__widget-title ltn__widget-title-border-2">
                      Search Objects
                    </h4>
                    <form action="#">
                      <input
                        type="text"
                        name="search"
                        placeholder="Search your keyword..."
                      />
                      <button type="submit">
                        <i className="fas fa-search" />
                      </button>
                    </form>
                  </div> */}
              {/* Form Widget */}
              {/* <div className="widget ltn__form-widget">
                    <h4 className="ltn__widget-title ltn__widget-title-border-2">
                      Drop Messege For Book
                    </h4>
                    <form action="#">
                      <input
                        type="text"
                        name="yourname"
                        placeholder="Your Name*"
                      />
                      <input
                        type="text"
                        name="youremail"
                        placeholder="Your e-Mail*"
                      />
                      <textarea
                        name="yourmessage"
                        placeholder="Write Message..."
                        defaultValue={""}
                      />
                      <button type="submit" className="btn theme-btn-1">
                        Send Messege
                      </button>
                    </form>
                  </div> */}
              {/* Top Rated Product Widget */}

              {/* Menu Widget (Category) */}

              {/* Popular Product Widget */}

              {/* Popular Post Widget */}

              {/* Social Media Widget */}
              {/* <div className="widget ltn__social-media-widget">
                    <h4 className="ltn__widget-title ltn__widget-title-border-2">
                      Follow us
                    </h4>
                    <div className="ltn__social-media-2">
                      <ul>
                        <li>
                          <a href="#" title="Facebook">
                            <i className="fab fa-facebook-f" />
                          </a>
                        </li>
                        <li>
                          <a href="#" title="Twitter">
                            <i className="fab fa-twitter" />
                          </a>
                        </li>
                        <li>
                          <a href="#" title="Linkedin">
                            <i className="fab fa-linkedin" />
                          </a>
                        </li>
                        <li>
                          <a href="#" title="Instagram">
                            <i className="fab fa-instagram" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
              {/* Tagcloud Widget */}

              {/* Banner Widget */}
              <div className="widget ltn__banner-widget d-none go-top">
                <Link to="/shop">
                  <img src={publicUrl + "assets/img/banner/2.jpg"} alt="#" />
                </Link>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShopDetails;
