import React, { useState, useEffect, useRef } from "react";

function InquiryDialog({ setOpenInquiryDialog }) {
  const innerDivRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (innerDivRef.current && !innerDivRef.current.contains(event.target)) {
        setOpenInquiryDialog();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [innerDivRef]);

  // const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState({
    subject: "",
    message: "",
  });

  useEffect(() => {
    handleCheck();
  }, [subject, message]);
  const handleCheck = () => {
    // const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let newErrors = {};

    // if (!email) {
    //   newErrors.email = "Please enter your email address";
    // } else if (!emailRegex.test(email)) {
    //   newErrors.email = "Please enter a valid email address";
    // }

    if (!subject) {
      newErrors.subject = "Please enter the subject";
    } else {
      newErrors.subject = "";
    }

    if (!message) {
      newErrors.message = "Please enter your message";
    } else {
      newErrors.message = "";
    }
    setError(newErrors);
  };

  const handleSendClick = () => {
    handleCheck();

    if (
      // email &&
      subject &&
      message &&
      // !error.email &&
      !error.subject &&
      !error.message
    ) {
      window.location.href = `mailto:Info@bry.properties?subject=${subject}&body=${message}`;
      setOpenInquiryDialog();
    }
  };
  const width = window.innerWidth;
  useEffect(() => {
    setError({
      email: "",
      name: "",
      subject: "",
      message: "",
    });
  }, []);
  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        bottom: "0",
        right: "0",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: "9999",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "10px",
          width: width > 768 ? "50%" : "90%",
        }}
        ref={innerDivRef}
      >
        <h2>Send Inquiry</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {/* <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              style={{
                marginBottom: "0",
              }}
            />
            {error.email && (
              <p style={{ color: "red", fontSize: 13 }}>{error.email}</p>
            )}
          </div> */}
          <div className="form-group">
            <label htmlFor="subject">Subject:</label>
            <input
              type="text"
              className="form-control"
              id="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              placeholder="Enter the subject"
              style={{
                marginBottom: "0",
              }}
            />
            {error.subject && (
              <p style={{ color: "red", fontSize: 13 }}>{error.subject}</p>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea
              className="form-control"
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Enter your message"
              style={{
                marginBottom: "0",
              }}
            ></textarea>
            {error.message && (
              <p style={{ color: "red", fontSize: 13 }}>{error.message}</p>
            )}
          </div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSendClick}
            style={{
              borderRadius: "4px",
            }}
          >
            Send
          </button>
        </form>
      </div>
    </div>
  );
}

export default InquiryDialog;
