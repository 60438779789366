import React, { useEffect } from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import ShogGrid from "./shop-components/shop-grid-v1";
// import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";

const ShopGrid_V1 = () => {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <PageHeader headertitle="Properties" />
      <ShogGrid />
      {/* <CallToActionV1 /> */}
      <Footer />
    </div>
  );
};

export default ShopGrid_V1;
