import React, { Component } from "react";

class ProductSliderV1 extends Component {
  render() {
    const receivedData = this.props.location.state.images || [];
    const folder = this.props.location.state.folder || "";

    return (
      <div className="ltn__img-slider-area mb-90">
        <div className="container-fluid">
          <div className="row ltn__image-slider-5-active slick-arrow-1 slick-arrow-1-inner ltn__no-gutter-all">
            {receivedData &&
              receivedData.length > 0 &&
              receivedData.map((item, i) => (
                <div className="col-lg-12" key={i}>
                  <div className="ltn__img-slide-item-4">
                    <img
                      src={"/assets/img/" + folder + "/" + item}
                      alt={item}
                    />
                  </div>
                </div>
              ))}

            {/* <div className="col-lg-12">
              <div className="ltn__img-slide-item-4">
                <a
                  href={publicUrl + "assets/img/img-slide/32.jpg"}
                  data-rel="lightcase:myCollection">
                  <img
                    src={publicUrl + "assets/img/img-slide/32.jpg"}
                    alt="Image"
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="ltn__img-slide-item-4">
                <a
                  href={publicUrl + "assets/img/img-slide/33.jpg"}
                  data-rel="lightcase:myCollection">
                  <img
                    src={publicUrl + "assets/img/img-slide/33.jpg"}
                    alt="Image"
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="ltn__img-slide-item-4">
                <a
                  href={publicUrl + "assets/img/img-slide/34.jpg"}
                  data-rel="lightcase:myCollection">
                  <img
                    src={publicUrl + "assets/img/img-slide/34.jpg"}
                    alt="Image"
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="ltn__img-slide-item-4">
                <a
                  href={publicUrl + "assets/img/img-slide/35.jpg"}
                  data-rel="lightcase:myCollection">
                  <img
                    src={publicUrl + "assets/img/img-slide/35.jpg"}
                    alt="Image"
                  />
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default ProductSliderV1;
