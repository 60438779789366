import React from "react";
import Navbar from "./global-components/navbar-v4";
import VideoV4 from "./section-components/video-v4";
import ProSlider from "./section-components/product-slider-v1";
import Footer from "./global-components/footer";
import { Link } from "react-router-dom";

const Home_V5 = () => {
  return (
    <div>
      <Navbar />
      <VideoV4 />
      <ProSlider />
      <Link to="/shop-grid">
        <div
          className=" btn-wrapper mb-4"
          style={{ display: "flex", justifyContent: "center" }}>
          <button
            className="btn theme-btn-1 btn-effect-1 text-uppercase"
            type="button">
            See All Properties
          </button>
        </div>
      </Link>

      <Footer />
    </div>
  );
};

export default Home_V5;
